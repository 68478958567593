import Vue from 'vue';
import App from './App.vue';
import Bus from './utils/bus.js';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import MvcFramework from './assets/js/mvcf.js';
import Vuelidate from 'vuelidate';
import logger from '@/utils/helpers/logger';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueExcelXlsx from "vue-excel-xlsx";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas, far, fab);
import vuetify from './plugins/vuetify';

// deteccion de inactividad
import IdleVue from "idle-vue";
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3600000, // 1 hour
  startAtIdle: false
});

Vue.use(VueGoogleMaps);
Vue.use(VueExcelXlsx);
Vue.use(Bus);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(MvcFramework);
Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  const error = {
    err,
    vm,
    info
  };
  logger.save(error);
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');