import axios from "@/axios/axios-api";
import messages from "@/utils/enums/messages";
import getAPIUrl from "@/utils/helpers/getAPIUrl";

const actions = {
  async getMenuApp() {
    const response = await axios.get("api/menuApp");
    return response.data.data;
  },
  async getMenusTabsApp() {
    const response = await axios.get("api/menuApp/MenuyTabs");
    return response.data.data;
  },
  async saveMenusTabsApp(context, payload) {
    const response = await axios.post("api/menuApp/MenuyTabs", payload);
    return response.data;
  },
  async getImgMenuTabsApp(context, payload) {
    const token = localStorage.getItem("token");
    const urlAPI = await getAPIUrl();
    return new Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();
      if (process.env.NODE_ENV == "development") {
        xhr.open(
          "GET",
          `${urlAPI}/api/archivo/get-img/${payload}`,
          true
        );
      } else {
        xhr.open(
          "GET",
          `${
            urlAPI.endsWith("/") ? urlAPI.slice(0, -1) : urlAPI
          }/api/archivo/get-img/${payload}`,
          true
        );
      }
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.responseType = "blob";
  
      xhr.onload = e => {
        if (e.target.status === 200) {
          let blob = e.target.response;
          resolve(blob);
        } else {
          reject(e.target.status);
        }
      };
      xhr.onerror = () => {
        reject(new Error("Error de red al intentar recuperar la imagen."));
      };
      xhr.send();
    });
  },
  async getTabOptions() {
    const response = await axios.get("api/menuApp/TabOptions");
    return response.data.data;
  },

  async updateTabOptions(context, tabOptionDTO) {
    const response = await axios.put("api/menuApp/TabOptions", tabOptionDTO);
    return response;
  },

  async updateMenuApp(context, menuAppDTO) {
    const response = await axios.put("api/menuApp/MenuApp", menuAppDTO);
    return response;
  },

  async generateEnlace(context, payload) {
    const result = await axios.post("api/enlacesutiles", payload.bodyParams);
    context.commit(
      "user/setAlertMessage",
      {
        type:
          result.data.data != null || result.data.data != "undefined"
            ? "success"
            : "error",
        message:
          result.data.data != null || result.data.data != "undefined"
            ? messages.ADD_SUCCESS
            : messages.ADD_ERROR
      },
      { root: true }
    );

    return result.data.result;
  },
  async getEnlacesUtilesInfoTable(context) {
    const result = await axios.get("api/enlacesutiles");
    return result.data.data;
  },

  async getEnlaceById(context, payload) {
    const enlace = await axios.get(
      `api/enlacesutiles/enlaceUtilById?enlaceId=${payload.enlaceId}`
    );
    return enlace.data.data;
  },

  async updateEnlace(context, enlaceDTO) {
    let response = await axios.put("api/enlacesutiles", enlaceDTO);
    context.commit(
      "user/setAlertMessage",
      {
        type:
          response.data.data != null || response.data.data != "undefined"
            ? "success"
            : "error",
        message:
          response.data.data != null || response.data.data != "undefined"
            ? messages.UPDATE_SUCCESS
            : messages.ADD_ERROR
      },
      { root: true }
    );
    return response.data.result;
  },

  async deleteEnlaceById(context, payload) {
    let response = await axios.delete(
      `api/enlacesutiles?enlaceId=${payload.enlaceId}`
    );
    context.commit(
      "user/setAlertMessage",
      {
        type: response.data.data == true ? "success" : "error",
        message:
          response.data.data == true
            ? messages.DELETE_SUCCESS
            : messages.DELETE_ERROR
      },
      { root: true }
    );
    return response.data;
  },

  async getCombos(context) {
    let result = await axios.get("api/enlacesutiles/combotiposenlaces");
    return result.data.data;
  },
  async getApps() {
    let result = await axios.get("api/taxonomy/apps");
    return result.data;
  },
  async listarRegistros(context, payload) {
    const response = await axios.post(
      "api/aplicacionMovil/ListarDispositivo",
      payload
    );
    return response.data;
  },
  async tokenApp(context, payload) {
    const response = await axios.post("api/appBenef/tokenApp", payload);
    return response;
  }
};

export default actions;
