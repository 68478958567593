//import { mapGetters } from 'vuex';
import axios from "@/axios/axios-api";
import getAPIUrl from "@/utils/helpers/getAPIUrl";

const actions = {
  async fetchProveedoresByQuery(context, payload) {
    if (payload.input && payload.input.length >= 3) {
      const response = await axios.get(
        `api/taxonomy/findProveedores?search=${payload.input}`
      );
      return response.data;
    }
  },
  async getTiposValoresModuloProveedores() {
    const response = await axios.get(
      "api/taxonomy/tiposValoresModuloProveedores"
    );
    return response.data;
  },
  async consultaOrdenesPago(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/consultaOrdenesPago",
      payload
    );
    return response.data.data;
  },
  async getDetalleOrdenPago(context, payload) {
    const response = await axios.get(
      `api/ordenesPago/detalleOrdenPago/${payload}`
    );
    return response.data.data;
  },
  async fetchTipoValoresRetenciones(context, payload) {
    const response = await axios.get(
      `api/taxonomy/tipoValoresRetenciones/${payload}`
    );
    return response.data.data;
  },
  async getTiposComprobantesOPByFormatoImpresion(context, payload) {
    const response = await axios.get(
      `api/taxonomy/tipoComprobantesOrdenesPago/${payload}`
    );
    return response.data.data;
  },
  async getAllTipoComprobantes(context, payload) {
    const response = await axios.get(
      `api/taxonomy/GetAllTipoComprobantes/${payload}`
    );
    return response.data.data;
  },
  async getTipoComprobantesFacturaContado(context, payload) {
    const response = await axios.get(
      `api/taxonomy/GetTipoComprobantesFacturaContado/${payload}`
    );
    return response.data.data;
  },
  async fetchFormatoImpresionVariables(context, payload) {
    const response = await axios.get(
      `api/taxonomy/formatoImpresionVariables/${payload}`
    );
    return response.data;
  },
  async fetchFormatoImpresionTipo(context, payload) {
    const response = await axios.get(
      `api/taxonomy/formatoImpresionTipo/${payload}`
    );
    return response.data;
  },
  async fetchFormatoImpresionOP() {
    const response = await axios.get("api/ordenesPago/formatoImpresionOP");
    return response.data.data;
  },
  async saveFormatoImpresionOP(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/formatoImpresionOP",
      payload
    );
    return response;
  },
  async deleteFormatoImpresionOP(context, payload) {
    const response = await axios.delete(
      `api/ordenesPago/formatoImpresionOP/${payload}`
    );
    return response;
  },
  async imprimirArchivoOrdenesPagoPdf(context, payload) {
    const token = localStorage.getItem("token");
    const urlAPI = await getAPIUrl();
    return new Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();
      if (process.env.NODE_ENV == "development") {
        xhr.open("POST", `${urlAPI}/api/ordenesPago/impresionOrdenPago`, true);
      } else {
        xhr.open(
          "POST",
          `${
            urlAPI.endsWith("/") ? urlAPI.slice(0, -1) : urlAPI
          }/api/ordenesPago/impresionOrdenPago`,
          true
        );
      }
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Content-type", "application/json;charset=UTF-8");
      xhr.responseType = "arraybuffer";

      let params = {
        id: payload.id,
        tipoImpresion: payload.tipoImpresion
      };
      xhr.send(JSON.stringify(params));

      // en Firefox no funciona con este tratamiento el preview del navegador para imprimir un archivo
      // por lo que en la parte del "else" lo descarga como pdf directamente
      // VER TRATAMIENTO PARA QUE SE PUEDA IMPRIMIR DIRECTAMENTE
      if (payload.browser !== "F") {
        xhr.onload = e => {
          if (e.target.status === 200) {
            let blob = new Blob([e.target.response], {
              type: "application/pdf"
            });
            let objFra = document.querySelector("#imprimirOrdenPago");
            if (objFra === null) {
              objFra = document.createElement("iframe");
              objFra.setAttribute("id", "imprimirOrdenPago");
            } else {
              objFra = document.getElementById("imprimirOrdenPago");
            }
            objFra.style.visibility = "hidden";
            objFra.src = window.URL.createObjectURL(blob);
            document.body.appendChild(objFra);
            objFra.contentWindow.focus();
            objFra.contentWindow.print();
          } else {
            context.commit(
              "user/setAlertMessage",
              {
                type: "warning",
                message: "No se pudo recuperar el archivo."
              },
              { root: true }
            );
          }
        };
      } else {
        xhr.onload = e => {
          if (e.target.status === 200) {
            resolve(e.target.status);
            const fileName = payload.fileName;
            let blob = new Blob([e.target.response], {
              type: "application/pdf"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", fileName);
            link.download = fileName;
            link.click();
          } else {
            context.commit(
              "user/setAlertMessage",
              {
                type: "warning",
                message: "No se pudo recuperar el archivo"
              },
              { root: true }
            );
            reject(e.target.status);
          }
        };
      }
    });
  },
  async crearProcesoEnvioMasivoOP(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/CrearProcesoEnvioMasivoOP",
      payload
    );
    return response;
  },
  async getHistorialEnviosOP(context, payload) {
    const response = await axios.get(
      `api/ordenesPago/HistorialEnviosOP/${payload}`
    );
    return response.data.data;
  },
  async envioEmailIndividualOP(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/SendEmailIndividualOP",
      payload
    );
    return response;
  },
  async getProcesoEnvioEmailMasivoOP(context, payload) {
    const response = await axios.post(
      "api/envioEmailMasivo/getProcesoEnvioEmailMasivoOP",
      payload
    );
    return response.data;
  },
  async fetchTiposProveedores() {
    const response = await axios.get("api/taxonomy/tiposProveedores/");
    return response.data;
  },
  async anularProcesoEnvioEmailMasivoOP(context, payload) {
    const response = await axios.delete(
      `api/envioEmailMasivo/anularProcesoEmailMasivoOP/${payload}`
    );
    return response;
  },
  async reenviarProcesoEnvioEmailMasivoOP(context, payload) {
    const response = await axios.get(
      `api/envioEmailMasivo/reenviarProcesoEmailMasivoOP/${payload}`
    );
    return response;
  },
  async fetchTiposComprobantesOP() {
    const response = await axios.get("api/taxonomy/tiposComprobantesOP/");
    return response.data;
  },
  async fetchEnviosConfiguracionOrdenesPago() {
    const response = await axios.get(
      "api/enviosConfiguracion/enviosConfiguracionOP"
    );
    return response.data.data;
  },
  async deleteEnvioConfiguracionOrdenesPago(context, payload) {
    const response = await axios.delete(
      `api/enviosConfiguracion/enviosConfiguracionOP/${payload}`
    );
    return response;
  },
  async getProcesosEnviosEstandarByModulo(context, payload) {
    const response = await axios.get(
      `api/taxonomy/procesosEnviosEstandar/${payload}`
    );
    return response.data;
  },
  async getEnvioConfiguracionOrdenesPagoById(context, payload) {
    const response = await axios.get(
      `api/enviosConfiguracion/enviosConfiguracionOP/${payload}`
    );
    return response.data.data;
  },
  async getProcesoEnvioOrdenesPagoDetalleById(context, payload) {
    const response = await axios.get(
      `api/enviosConfiguracion/enviosConfiguracionOP/procesoDetalle/${payload}`
    );
    return response.data.data;
  },
  async saveEnvioConfiguracionOrdenesPago(context, payload) {
    const response = await axios.post(
      "api/enviosConfiguracion/enviosConfiguracionOP",
      payload
    );
    return response;
  },
  async getVariablesEnviosEstandarByModulo(context, payload) {
    const response = await axios.get(
      `api/taxonomy/variablesEnviosEstandar/${payload}`
    );
    return response.data;
  },
  async saveLogEnviosEstandarOrdenesPago(context, payload) {
    const response = await axios.post(
      "api/ordenesPago/logEnviosEstandarOrdenesPago",
      payload
    );
    return response;
  },
  async envioEstandarOPRequiereDatosXPantalla(context, payload) {
    const response = await axios.get(
      `api/ordenesPago/envioRequiereDatosPantalla/${payload}`
    );
    return response.data.data;
  },
  async getCtaCteProveedoresByFilter(context, payload) {
    const response = await axios.post(
      "api/proveedores/getCuentaCorrienteProveedores",
      payload
    );
    return response.data.data;
  },
  async getComprobanteProveedorById(context, payload) {
    const response = await axios.post(
      "api/proveedores/getComprobanteById",
      payload
    );
    return response.data.data;
  },
  async getComprobantesProveedorByFilters(context, payload) {
    const response = await axios.post(
      "api/proveedores/GetComprobantesProveedor",
      payload
    );
    return response.data.data;
  },
  async getParametroGralFechaDesde(context, payload) {
    const response = await axios.get(`api/proveedores/parametroGralFechaDesde`);
    return response.data.data;
  },
  async getEntidadesFacturantes(context, payload) {
    const response = await axios.get(`api/EntidadesFacturantes`);
    return response.data.data;
  },
  async findProveedoresByFilters(context, payload) {
    const response = await axios.post(
      "api/proveedores/FindProveedoresByFilters",
      payload
    );
    return response.data.data;
  },
  async validateDeleteComprobante(context, payload) {
    const response = await axios.get(
      `api/proveedores/ValidateDeleteComprobante/${payload}`
    );
    return response.data.data;
  },
  async deleteComprobantesProveedor(context, payload) {
    const response = await axios.delete(
      `api/proveedores/DeleteComprobanteProveedor/${payload}`
    );
    return response.data.data;
  },
  async getCentrosCostosByEmpresa(context, empresaIdConta) {
    const response = await axios.get(
      `api/Proveedores/GetCentrosCostosByEmpresa/${empresaIdConta}`
    );
    return response.data.data;
  },
  async getCentroCostoById(context, ccId) {
    const response = await axios.get(
      `api/Proveedores/GetCentrosCostosxId/${ccId}`
    );
    return response.data.data;
  },
  async getImputacionContableDetalle(context, tiId) {
    const response = await axios.get(
      `api/Proveedores/GetImputacionContableDetalle/${tiId}`
    );
    return response.data.data;
  },
  async getImputContablesByEmpresa(context, empresaIdConta) {
    const response = await axios.get(
      `api/Proveedores/GetImputContablesByEmpresa/${empresaIdConta}`
    );
    return response.data.data;
  },
  async getImputContablesByEmpresaxFechaContable(context, payload) {
    const response = await axios.post(
      `api/Proveedores/GetImputContablesByEmpresaxFechaContable`,
      payload
    );
    return response.data.data;
  },
  async getCuentaContableBuscar(context, payload) {
    const response = await axios.get(
      `api/Proveedores/GetCuentaContableBuscar?planCtaId=${payload.planCtaId}&nombre=${payload.nombre}`
    );
    return response.data.data;
  },
  async GetCuentaContableBuscarxCodigoyNombre(context, payload) {
    const response = await axios.get(
      `api/Proveedores/GetCuentaContableBuscarxCodigoyNombre?planCtaId=${
        payload.planCtaId
      }&nombre=${payload.nombre ?? ""}&codigo=${payload.codigo ?? ""}`
    );
    return response.data.data;
  },
  async getCuentaContableBuscarxId(context, ctaId) {
    const response = await axios.get(
      `api/Proveedores/GetCuentaContableBuscarxId/${ctaId}`
    );
    return response.data.data;
  },
  async getImputacionesContablesProveedores(context, payload) {
    const response = await axios.post(
      `api/Proveedores/get-imputaciones-contables-proveedores`,
      payload
    );
    return response.data.data;
  },
  async getCentrosCostosxImputacionesContablesProveedores(context, payload) {
    const response = await axios.get(
      `api/Proveedores/get-imputaciones-contables-detalle-centrocosto-proveedoresxId/${payload}`
    );
    return response.data.data;
  },
  async getImputacionesContablesDeProveedoresxId(context, CompTiId) {
    const response = await axios.get(
      `api/Proveedores/get-imputaciones-contables-proveedoresxId/${CompTiId}`
    );
    return response.data.data;
  },
  async getImputacionesContablesDetalleCentroCostoProveedoresxId(
    context,
    CompTiId
  ) {
    const response = await axios.get(
      `api/Proveedores/get-imputaciones-contables-detalle-centrocosto-proveedoresxId/${CompTiId}`
    );
    return response.data.data;
  },
  async getImputacionesContablesDetalleCentroCostoProveedoresxId(
    context,
    CompTiId
  ) {
    const response = await axios.get(
      `api/Proveedores/get-imputaciones-contables-detalle-centrocosto-proveedoresxId/${CompTiId}`
    );
    return response.data.data;
  },
  async getTipoComprobanteProveedorById(context, TCompId) {
    const response = await axios.get(
      `api/Proveedores/get-tipo-comprobante-proveedor-Id/${TCompId}`
    );
    return response.data.data;
  },
  async getParametrosGeneralesProveedorCargaFactura(context, payload) {
    const response = await axios.get(
      `api/Proveedores/get-parametros-generales-proveedor`
    );
    return response.data.data;
  },
  async getTiposComprobantesProveedores(context, payload) {
    const response = await axios.post(
      `api/Proveedores/get-tipos-comprobantes-proveedores`,
      payload
    );
    return response.data.data;
  },
  async getTipoCompTieneNumAutomatico(context, payload) {
    const response = await axios.post(
      `api/Proveedores/get-tipo-comprobante-proveedor-tiene-numeradorautomatico`,
      payload
    );
    return response.data.data;
  },
  async getLetraTipoCompProveedor(context, payload) {
    const response = await axios.post(
      `api/Proveedores/get-tipo-comprobante-proveedor-buscar-letra`,
      payload
    );
    return response.data.data;
  },
  async getIvaPorcentajes(context, payload) {
    const response = await axios.post(
      `api/Proveedores/GetIvaPorcentajes`,
      payload
    );
    return response.data.data;
  },
  async getIvaPorcValorXId(context, payload) {
    const response = await axios.post(
      `api/Proveedores/GetIvaPorcValorXId`,
      payload
    );
    return response.data.data;
  },
  async saveFacturaProveedores(context, payload) {
    const response = await axios.post(
      `api/Proveedores/Save-factura-proveedor`,
      payload
    );
    return response.data.data;
  },
  async getJurisdiccionesIIBBProveedor(context, payload) {
    const response = await axios.post(
      `api/Proveedores/get-jurisdiccionesiibb-por-proveedor`,
      payload
    );
    return response.data.data;
  },
  async getPlazoPagoProveedorById(context, provId) {
    const response = await axios.get(
      `api/Proveedores/get-plazo-pago-proveedor/${provId}`
    );
    return response.data.data;
  },
  async getFacturaDetalleCompletoProv(context, movProvId) {
    const response = await axios.get(
      `api/Proveedores/Get-Factura-Detalle-Completo-Prov/${movProvId}`
    );
    return response.data.data;
  },
  async getAutorizacionesAPagarConfig(context, payload) {
    const response = await axios.post(
      `api/ProveedoresConfiguracion/consultar-configuracion`,
      payload
    );
    return response.data.data;
  },
  async deleteConfigAutorizacionesAPagar(context, payload) {
    const response = await axios.delete(
      `api/ProveedoresConfiguracion/eliminar-configuracion/${payload}`
    );
    return response;
  },
  async getUsuariosParaAutorizar() {
    const response = await axios.get(
      `api/ProveedoresConfiguracion/listar-usuarios-para-autorizar-factura`
    );
    return response.data.data;
  },
  async getEmpresaXMoneda() {
    const response = await axios.get(
      `api/ProveedoresConfiguracion/listar-empresa-por-moneda`
    );
    return response.data.data;
  },
  async saveConfigAutorizaciones(context, payload) {
    const response = await axios.post(
      `api/ProveedoresConfiguracion/grabar-configuracion`,
      payload
    );
    return response;
  },
  async consultaFacturasParaAutorizar(context, payload) {
    const response = await axios.post(
      `api/Proveedores/consulta-facturas-para-autorizar`,
      payload
    );
    return response.data.data;
  },
  async grabarAutorizarAnularFacturas(context, payload) {
    const response = await axios.post(
      `api/Proveedores/grabar-autorizar-anularautorizar-facturas`,
      payload
    );
    return response.data.data;
  },
  async getHistorialAutorizacionesPorComprobante(context, payload) {
    const response = await axios.post(
      `api/Proveedores/consultar-historial-autorizacion-por-comprobante/${payload}`
    );
    return response.data.data;
  },
  async getMonedaDeProveedor(context, payload) {
    const response = await axios.get(
      `api/Proveedores/Get-Moneda-De-Proveedor/${payload}`
    );
    return response.data.data;
  },
  async getDatosLiquidaYSssComp(context, payload) {
    const response = await axios.get(
      `api/Proveedores/get-Datos-Liquida-Sss-Comprobante/${payload}`
    );
    return response.data.data;
  },
};
export default actions;
