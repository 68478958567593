const helper = {
  getCookie(cname) {
    let name = `${cname}=`;
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  sendXHR(token, method, url) {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.responseType = "arraybuffer";
      xhr.onload = () => {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        }
      };
      xhr.onerror = () => {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.send();
    });
  },
  ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  },
  str2ab(str) {
    let buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    let bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  },
  mapToSelectData(listFields) {
    return listFields.data.map(items => {
      return {
        text: items.value,
        value: items.id
      };
    });
  },

  downloadFile(data, name, type) {
    var fileURL = window.URL.createObjectURL(new Blob([data]));
    var fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", name + "." + type);
    document.body.appendChild(fileLink);

    fileLink.click();
  },
  // flatten a multidimensional array
  flatArray(array) {
    let result = [];
    array.forEach(a => {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(this.flatArray(a.children));
      }
    });
    return result;
  },
  removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  },
  formatDate(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  },
  parseDateToIso(date) {
    if (!date) return null;
    if (date.length === 10) {
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  floatToMoneyString(number, sigla, ocultarSigno) {
    let result;
    if (number != null) {
      const formattedNumber = number.toFixed(2);
      let [integerPart, decimalPart] = formattedNumber.split(".");
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      result = `${sigla ?? ""}$${integerPart},${decimalPart}`;
    } else {
      result = `${sigla ?? ""}$0,00`;
    }
    if (ocultarSigno === true) {
      result = result.replace("$", "");
    }
    return result;
  }
};

export default helper;
