import axios from "@/axios/axios-api";
import getAPIUrl from "@/utils/helpers/getAPIUrl";

const actions = {
  async getTipoOperacion(context, payload) {
    const response = await axios.get(`api/taxonomy/GetTipoOperacion`);
    return response.data.data;
  },
  async getParametroGralCargaFacturaEnCero(context, payload) {
    const response = await axios.get(`api/ParametrosGenerales/get-parametrogral-cargafact`);
    return response.data.data;
  },
};

export default actions;
